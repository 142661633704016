/** @jsx jsx */
import React from "react";
import { graphql } from "gatsby";
import { jsx, Container, Box, Flex, Text } from 'theme-ui';
import Layout from '../components/Layout';
import HeroSection from "../components/sections/heroSection";
import SEO from "../components/seo";
import useClientWindowSize from "../hooks/useClientWindowSize";
import Icon from "../components/shared/Icon";
import Action from "../components/shared/Action";

export const pageQuery = graphql`
  query pricingPage {
    allContentfulPage(filter: {slug: {eq: "pricing-landing"}}) {
      nodes {
        id
        seoMetadata {
          description
          keywords
          ogImage {
            gatsbyImageData
            title
          }
          title
        }
        sections {
          ... on ContentfulHeroSection {
            ...ContentfulHeroSectionFragment
          }
        }
        bannerData {
          bannerImage {
            gatsbyImageData(width:30)
          }
          bannerSlug
          bannerText {
            raw
          }
          label
          link
        }
      }
    }
    allContentfulPricingOffer(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          name
          summary
          dataAssetFeaturesAndLimits
          dataAssetTesting
          validationResultsRetention
          expectationsGallery
          validationAlertConfiguration
          auditingAndLogging
          accountFeaturesAndLimits
          usersPerAccount
          userRbac
          teamSupportOrganizations
          socialLoginGoogle
          corporateIdpSamlssodap
          supportFeatures
          technicalSupport
          onboardingSupport
          uptimeSla
          supportResponseSlo
          price
          createdAt(formatString: "MMMM DD, YYYY")
          action {
            ... on ContentfulAction {
              ...ActionFragment
              
            }
          }
        }
      }
    }
    file(relativePath: {eq: "background-pattern.png"}) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 2000,
        )
      }
    }
    contentfulAction(contentful_id: {eq: "1lWl0IQWh9zaNPPHenjZru"}) {
      id
      label
      style
      url
      fullWidth
      classname
      classnameForAnalytics
      actionEvent
    }
  }
`;

const PricingPage = ({ data }) => {
  const { sections, seoMetadata, bannerData } = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];
  const pricingOffers = data.allContentfulPricingOffer.edges;
  const scheduleAction = data.contentfulAction;
  const { mobile, desktop, notReady } = useClientWindowSize();

  const finalFields = [
    {
      "id": "name",
      "name": "Name",
    },
    {
      "id": "action",
      "name": "Action",
    },
    {
      "id": "dataAssetFeaturesAndLimits",
      "name": "Data Assets",
      "section": true
    },
    {
      "id": "dataAssetTesting",
      "name": "Data Assets under test (e.g. monitored tables)",
    },
    {
      "id": "validationResultsRetention",
      "name": "Validation Results retention",
    },
    {
      "id": "expectationsGallery",
      "name": "Expectations Gallery",
    },
    {
      "id": "validationAlertConfiguration",
      "name": "Validation alert configuration",
    },
    {
      "id": "auditingAndLogging",
      "name": "Auditing and logging",
    },
    {
      "id": "accountFeaturesAndLimits",
      "name": "Accounts",
      "section": true
    },
    {
      "id": "usersPerAccount",
      "name": "Users per organization",
    },
    {
      "id": "socialLoginGoogle",
      "name": "Social login (Google)",
    },
    {
      "id": "userRbac",
      "name": "Role-based Access Control",
    },
    {
      "id": "teamSupportOrganizations",
      "name": "Team support (organizations)",
    },
    {
      "id": "corporateIdpSamlssodap",
      "name": "Corporate IDP - SAML/SSO/LDAP",
    },
    {
      "id": "supportFeatures",
      "name": "Support",
      "section": true
    },
    {
      "id": "technicalSupport",
      "name": "Technical support",
    },
    {
      "id": "onboardingSupport",
      "name": "Dedicated support",
    },
    {
      "id": "uptimeSla",
      "name": "Uptime SLA",
    },
    {
      "id": "supportResponseSlo",
      "name": "Support response SLO",
    }
  ];

  const stringStyles = {
    letterSpacing: '0px',
    fontFamily: "fontSource",
    fontSize: `${desktop ? "18px" : "14px"}`,
    wordBreak: 'break-word',
  };

  const innerLabelStyle = {
    ...stringStyles,
    fontWeight: "lessBold",
    mb: 3,
    textAlign: 'center',
  };

  const innerCellStyles = {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: '#ffffff',
    height: '100%',
    p: 3,
  };

  const controlSections = [
    'accountFeaturesAndLimits',
    'commercialFeatures',
    'dataAssetFeaturesAndLimits',
    'supportFeatures'
  ];


  const renderFieldValue = (fieldValue, fieldId, fieldName, pricingOffers) => {
    if (!fieldValue) {
      return null;
    }

    if (controlSections.includes(fieldId) && desktop) {
      return '';
    }

    const isString = typeof fieldValue === "string";
    const isObject = typeof fieldValue === "object";

    if (fieldId === 'action' && isObject) {
      // debugger
      return (
        <Flex sx={{ ...innerCellStyles, py: 4, px: "0" }}>
          <Text sx={{ mb: 3, color: pricingOffers.price?'midNightBlue': 'white', textAlign: 'center' }} as="h5">{ pricingOffers.price?pricingOffers.price:mobile?'':'-' }</Text>
          {fieldValue.map((field) => {
            if (field.actionEvent == 'link') {
              return (
                <Action {...field} marginBottom="10px" fontsize="18px"  fontColor="#FF6310" fontWeight="lessBold"  paddingCustom={`${mobile && "5px 50px"}`} sx={{ letterSpacing: "2px !important", textDecoration: "underline" }} />
              )
            } else {
              return (
                <Action {...field} marginBottom="10px" fontsize="18px" borderCustom="2px solid #FF6310" fontColor="#FF6310" fontWeight="lessBold"  paddingCustom={`${mobile && "5px 50px"}`}  sx={{ letterSpacing: "2px !important"}} />
              )
            }
          })} 
        </Flex>
      );
    }

    if (isString) {
      const hasPipe =fieldValue.includes('|');

      if (hasPipe) {
        const value1AfterPipe = fieldValue.split('|')[1].trim();
        const value2AfterPipe = fieldValue.split('|')[2]?.trim();

        return (
          <Flex sx={{ ...innerCellStyles }}>
            {!desktop && (
              <Text sx={{...innerLabelStyle }}>{fieldName}:</Text>
            )}
            <Icon icon="fa-check" iconColor='#4DC0B4' iconSize="22" sx={{ mb: 2 }} />
            <Text sx={{ ...stringStyles, textAlign: 'center' }}>{value1AfterPipe}</Text>
            <Text sx={{ ...stringStyles, textAlign: 'center' }}>{value2AfterPipe}</Text>
          </Flex>
        );
      }

      if (!hasPipe && fieldValue.startsWith('Yes')) {
        return (
          <Flex sx={{ ...innerCellStyles }}>
            {!desktop && (
              <Text sx={{...innerLabelStyle }}>{fieldName}:</Text>
            )}
            <Icon icon="fa-check" iconColor='#4DC0B4' iconSize="22" />
          </Flex>
        );
      }

      if (fieldValue.startsWith('No')) {
        return (
          <Flex sx={{ ...innerCellStyles }}>
            {!desktop && (
              <Text sx={{...innerLabelStyle }}>{fieldName}:</Text>
            )}
            <Icon icon="io-close" iconColor='#E2226B' iconSize="22" />
          </Flex>
        );
      }

      return  (
        <Flex sx={{ ...innerCellStyles }}>
          {!desktop && controlSections.includes(fieldId) && (
            <Text sx={{...innerLabelStyle, fontWeight: '800', fontSize: '16px', mb: '0' }}>{fieldName}</Text>
          )}
          {!desktop && !controlSections.includes(fieldId) && (
            <Text sx={{...innerLabelStyle,  }}>{fieldName}:</Text>
          )}
          {!controlSections.includes(fieldId) && (
            <Text sx={{ ...stringStyles, textAlign: 'center' }}>{fieldValue}</Text>
          )}
        </Flex>
      );
    }
  };

  const renderFieldLabel = (fieldId, fieldName) => {
    const control = ['name', 'action', 'summary'];
    if (control.includes(fieldId)) {
      return '';
    }

    let labelWeight = 'lessBold'
    if (controlSections.includes(fieldId))
      labelWeight = '800'

    return (
      <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', height: '100%', pl: '20px', py: 3, backgroundColor: `${controlSections.includes(fieldId) ? '#ffffff' : 'none'}` }}>
        <Text sx={{...stringStyles, fontWeight: labelWeight}}>{fieldName}</Text>
      </Flex>
    );
  };

  const noBorder = [
    0,
    finalFields.length-1,
  ];

  const managedFieldsResult = (
    <Container>
      <Box className="gx-pricing-rows">
        {finalFields.map((field, index) => (
          <Flex
            key={field.id}
            sx={{
              width: '100%',
              borderBottom: `${!noBorder.includes(index) ? (desktop ? '1px solid #E8E8E8' : '1px solid #F4F4F7')  : 'none'}`,
              background: `${controlSections.includes(field.id) && desktop ? '#FFFFFF' : 'none'}` }}
          >
            { desktop && (
              <Box sx={{ flex: '0 0 25%', height: '100%' }}>
                {renderFieldLabel(field.id, field.name)}
              </Box>
            )}
            {pricingOffers.map(({ node }) => (
              <Box key={`${node.id}-${field.id}-data`} sx={{ flex: `${desktop ? '0 0 25%' : '0 0 33.3%'}`, p: `${desktop ? ' 0 0 0 20px' : '0 10px'}`}}>
                {field.id === 'name' ? (
                  <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', backgroundColor: '#ffffff', py: 3, px: 2, height: '100%' }}>
                    <Text sx={{ mb: 3, color: "midNightBlue" }} as="h3">{node.name}</Text>
                    <Text sx={{ ...stringStyles, textAlign: 'center' }}>{node.summary}</Text>
                  </Flex>
                ) : (
                  <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', backgroundColor: '#ffffff', height: '100%' }}>
                    { renderFieldValue(node[field.id], field.id, field.name, node) }
                  </Flex>
                )}
              </Box>
            ))}
          </Flex>
        ))}
        <Flex sx={{ justifyContent: "center", alignItems: "center", pt: 4}}>
          <Flex sx={{ justifyContent: "center", flex: `${desktop ? '0 0 25%' : '100%'}`, pl: `${desktop && "20px"}`, ml: "auto", mr: `${desktop && '25%'}` }}>
            <Action {...scheduleAction} />
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
  const managedFiledResultMobile = (
    <Container>
      <Flex sx={{ flexDirection: "column", width: '275px', mx: "auto" }} className="gx-pricing-columns">
        {pricingOffers.map(({ node }) => (
          <Flex key={node.id} sx={{ flexDirection: 'column', py: 2, backgroundColor: '#ffffff', '&:not(:last-of-type)': {mb: 4 }}}>
            {finalFields.map((field, index) => (
              field.id === 'name' ? (
                <Flex key={`${node.id}-${field.id}-value`} sx={{ borderBottom: `${index !== finalFields.length - 1 ? '1px solid #E8E8E8' : 'none'}`, justifyContent: 'center', alignItems: 'center' }}>
                  <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column', py: 3, px: 2, height: '100%' }}>
                    <Text sx={{ mb: 3, color: "midNightBlue" }} as="h3">{node.name}</Text>
                    <Text sx={{ ...stringStyles, textAlign: 'center' }}>{node.summary}</Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex key={`${node.id}-${field.id}-value`} sx={{ borderBottom: `${index !== finalFields.length - 1 ? '1px solid #E8E8E8' : 'none'}`, flexDirection: 'column', justifyContent: 'center', alignItems: 'center',}}>
                  {renderFieldValue(node[field.id], field.id, field.name, node)}
                </Flex>
              )
            ))}
          </Flex>
        ))}
        <Flex sx={{ justifyContent: "center", alignItems: "center", pt: 4 }}>
          <Action {...scheduleAction} />
        </Flex>
      </Flex>
    </Container>
  );

  const loadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '50vh',
    background: '#fff',
  };

  return (
    <Layout bannerData={bannerData}>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}
      {sections && sections.length > 0 && (
        <Box>
          <HeroSection sectionContent={sections[0]} outOfTheBoxImageFullRight={true} />
        </Box>
      )}

      <Box sx={{ backgroundColor: "#F4F4F7", p: `${desktop ? "50px 0 140px 0" : "50px 0 70px 0"}` }}>
        {!notReady && (
          mobile ? managedFiledResultMobile  : managedFieldsResult
        )}
      </Box>

      {sections && sections.length > 1 && (
        <HeroSection sectionContent={sections[1]} />
      )}
    </Layout>
  );
};

export default PricingPage;